import { employeeObject, selectedEmployeebject } from '@/models/employee'
const _ = require('lodash')

export default {
  newEmployee: _.cloneDeep(employeeObject),
  updateEmployee: _.cloneDeep(employeeObject),
  selectedEmployee: _.cloneDeep(selectedEmployeebject),
  newEmployeeFormsProgress: {
    employeeInformation: {
      employeeData: false,
      job: false,
      assignment: false,
      contact: false,
    },
  },
  newEmployeeErrors: {},
  updateEmployeeErrors: {},
  employeesList: [],
  skeletonLoading: true,
  searchParams: '',
  totalEmployeePages: 1,
  popupVacation: false,
  employeeVac: {},
  employeeVacList: [],
}
