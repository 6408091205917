import axios from 'axios'
import { selectedCandidateObject } from '@/models/candidates'

const _ = require('lodash')

export async function getCandidatesList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/candidatos/'
    url = params !== null ? `${url}?${params}` : url
    // when get the candidates list, Clean the actual candidate
    state.selectedCandidate = _.cloneDeep(selectedCandidateObject)

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getCandidateById({ commit }, id) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos del candidato',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/candidatos/${id}/`

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newCandidateAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/candidatos/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateCandidateAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/candidatos/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function saveCandidateDocs({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/docs/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
