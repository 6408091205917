const _ = require('lodash')

export function finalNewCandidate(store) {
  let finalCandidate = _.cloneDeep(store.newCandidate)
  // Format emails
  finalCandidate.emailsContactAdd = finalCandidate.emailsContactAdd.map(
    (email) => {
      return email.email
    }
  )

  // Format phones contact
  finalCandidate.phonesContactAdd = finalCandidate.phonesContactAdd.map(
    (phone) => {
      return phone.phone
    }
  )

  // Format addressAdd
  finalCandidate.addressAdd = {
    street: finalCandidate.addressAdd.street,
    postalCodeId: finalCandidate.addressAdd.selectedPostalCode.id,
  }
  return finalCandidate
}

export function finalUpdateCandidate(store) {
  let finalCandidate = _.cloneDeep(store.updateCandidate)

  // Format emails
  finalCandidate.emailsContactAdd = finalCandidate.emailsContactAdd.map(
    (email) => {
      return email.email
    }
  )

  // Format phones contact
  finalCandidate.phonesContactAdd = finalCandidate.phonesContactAdd.map(
    (phone) => {
      return phone.phone
    }
  )
  // Format addressAdd
  finalCandidate.addressAdd = {
    street: finalCandidate.addressAdd.street,
    postalCodeId: finalCandidate.addressAdd.selectedPostalCode.id,
  }
  return finalCandidate
}

export function getNewCandidateErrors(store) {
  let finalNewCandidateErrors = {
    generalInformation: {
      candidateData: [],
      contact: [],
      address: [],
    },
  }
  // Datos del candidato
  if (store.newCandidateErrors.hasOwnProperty('name')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Nombre',
      msg: store.newCandidateErrors.name,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('lastName')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Apellido Paterno',
      msg: store.newCandidateErrors.lastName,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('secondLastName')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Apellido Paterno',
      msg: store.newCandidateErrors.secondLastName,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('minimumSalaryExpectation')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Expectativa salarial mínima',
      msg: store.newCandidateErrors.minimumSalaryExpectation,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('maximumSalaryExpectation')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Expectativa salarial máxima',
      msg: store.newCandidateErrors.maximumSalaryExpectation,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('employmentStatusId')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Estatus laboral',
      msg: store.newCandidateErrors.employmentStatusId,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('jobVacancyId')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Puesto al que aplica',
      msg: store.newCandidateErrors.jobVacancyId,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('dateOfBirth')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Fechande nacimiento',
      msg: store.newCandidateErrors.dateOfBirth,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('nationalityId')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Nacionalidad',
      msg: store.newCandidateErrors.nationalityId,
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('recruitingMediumId')) {
    finalNewCandidateErrors.generalInformation.candidateData.push({
      name: 'Medio de reclutamiento',
      msg: store.newCandidateErrors.recruitingMediumId,
    })
  }
  // Contacto
  if (store.newCandidateErrors.hasOwnProperty('emailsContactAdd')) {
    finalNewCandidateErrors.generalInformation.contact.push({
      name: 'Email de contacto',
      msg: store.newCandidateErrors.emailsContactAdd[0],
    })
  }
  if (store.newCandidateErrors.hasOwnProperty('phonesContactAdd')) {
    finalNewCandidateErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.newCandidateErrors.phonesContactAdd[0],
    })
  }

  // Dirección
  if (store.newCandidateErrors.hasOwnProperty('addressAdd')) {
    finalNewCandidateErrors.generalInformation.address.push({
      name: 'Dirección',
      msg: store.newCandidateErrors.addressAdd,
    })
  }

  return finalNewCandidateErrors
}

export function getUpdateCandidateErrors(store) {
  let finalUpdateCandidateErrors = {
    generalInformation: {
      candidateData: [],
      contact: [],
      address: [],
    },
  }
  // Datos del candidato
  if (store.updateCandidateErrors.hasOwnProperty('name')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Nombre',
      msg: store.updateCandidateErrors.name,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('lastName')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Apellido Paterno',
      msg: store.updateCandidateErrors.lastName,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('secondLastName')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Apellido Paterno',
      msg: store.updateCandidateErrors.secondLastName,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('minimumSalaryExpectation')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Expectativa salarial mínima',
      msg: store.updateCandidateErrors.minimumSalaryExpectation,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('maximumSalaryExpectation')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Expectativa salarial máxima',
      msg: store.updateCandidateErrors.maximumSalaryExpectation,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('employmentStatusId')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Estatus laboral',
      msg: store.updateCandidateErrors.employmentStatusId,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('jobVacancyId')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Puesto al que aplica',
      msg: store.updateCandidateErrors.jobVacancyId,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('dateOfBirth')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Fechande nacimiento',
      msg: store.updateCandidateErrors.dateOfBirth,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('nationalityId')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Nacionalidad',
      msg: store.updateCandidateErrors.nationalityId,
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('recruitingMediumId')) {
    finalUpdateCandidateErrors.generalInformation.candidateData.push({
      name: 'Medio de reclutamiento',
      msg: store.updateCandidateErrors.recruitingMediumId,
    })
  }
  // Contacto
  if (store.updateCandidateErrors.hasOwnProperty('emailsContactAdd')) {
    finalUpdateCandidateErrors.generalInformation.contact.push({
      name: 'Email de contacto',
      msg: store.updateCandidateErrors.emailsContactAdd[0],
    })
  }
  if (store.updateCandidateErrors.hasOwnProperty('phonesContactAdd')) {
    finalUpdateCandidateErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.updateCandidateErrors.phonesContactAdd[0],
    })
  }

  // Dirección
  if (store.updateCandidateErrors.hasOwnProperty('addressAdd')) {
    finalUpdateCandidateErrors.generalInformation.address.push({
      name: 'Dirección',
      msg: store.updateCandidateErrors.addressAdd,
    })
  }

  return finalUpdateCandidateErrors
}
