export default {
  tokenAccess: null,
  tokenRefresh: null,
  user: {
    id: null,
    username: '',
    name: '',
    lastName: '',
    secondLastName: '',
    email: '',
    phoneNumber: '',
    employee: null,
    userprofile: {
      picture: '#',
      company: {
        id: null,
        name: '',
      },
      isRecruiter: false,
      isCompanyAdmin: false,
      role: '',
    },
  },
}
