/**
 * Get one product from storage
 * @param state
 */
export function isLogged(state) {
  return !!state.tokenAccess
}

export function isCompanyAdmin(state) {
  return state.user.userprofile.isCompanyAdmin
}

export function getUsername(state) {
  return state.user.username
}

export function isEmployee(state) {
  return state.user.userprofile.role === 'employee'
}
