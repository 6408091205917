import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newEmployeeAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/empleados/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateEmployeeAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/empleados/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function dismissalEmployeeAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/empleados-baja/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getEmployeesListAction({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/empleados/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getEmployeeByIdAction({ commit }, id) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos del empleado',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/empleados/${id}/`

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createEmployeePhotosAction({ commit }, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/empleados-fotos/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function saveDocumentAction({ commit }, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/empleados-documentos/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function sendEmailAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/invitacion-empleado/resend-invitation/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Crea el registro de nuevas vacaciones de empleados
 *
 */
export async function createVacationAction({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando vacaciones',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/vacaciones/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Actualiza un registro previo de vacaciones
 *
 */
export async function updateVacationAction({ commit }, { id, data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando vacaciones',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .patch(`/vacaciones/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getExcelAssistanceAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/asistencias/excel-assistance/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getUsersToEmployeeListAction({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/users/users_to_employee/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function associateUserAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/invitacion-empleado/associate-user/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function registerAssistanceAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/asistencias/admin-assistance/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getEmployeeVacationsListAction(
  { commit },
  params = null
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando vacaciones previas',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = '/vacaciones/employee-vacations/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        commit('setEmployeeVacList', response.data)
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  })
}
