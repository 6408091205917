import { candidateObject, selectedCandidateObject } from '@/models/candidates'
const _ = require('lodash')

export default {
  newCandidate: _.cloneDeep(candidateObject),
  updateCandidate: _.cloneDeep(candidateObject),
  selectedCandidate: _.cloneDeep(selectedCandidateObject),
  newCandidateFormsProgress: {
    generalInformation: {
      candidateData: false,
      benefits: false,
      contact: false,
      address: false,
      comments: false,
    },
    studies: {
      scholarship: false,
      languages: false,
      certifications: false,
      courses: false,
      comments: false,
    },
    jobExperience: {
      jobExperience: false,
      comments: false,
    },
    personality: {
      qualities: false,
      opportunities: false,
      perceptions: false,
    },
    docs: {
      ratings: false,
    },
  },
  newCandidateErrors: {},
  updateCandidateErrors: {},
  candidatesList: [],
  candidateDocs: new FormData(),
  skeletonLoading: true,
  searchParams: '',
  totalCandidatesPages: 1,
}
