import utils from '@/utils/utils'

export let candidateObject = {
  name: '',
  lastName: '',
  secondLastName: '',
  gender: '',
  minimumSalaryExpectation: '',
  maximumSalaryExpectation: '',
  employmentStatusId: '',
  availabilityToStart: '',
  dateOfBirth: '',
  jobVacancyId: '',
  recruitingMediumId: '',
  nationalityId: '',
  benefitsId: [],
  emailsContactAdd: [
    {
      id: utils.randomId(),
      email: '',
    },
  ],
  phonesContactAdd: [
    {
      id: utils.randomId(),
      phone: '',
    },
  ],
  addressAdd: {
    street: '',
    selectedPostalCode: {
      id: '-1',
      postalCode: '',
      settlement: '',
      municipality: '',
      city: '',
      estate: '',
    },
    postalCodeStr: '',
  },
  generalInformationComments: '',
  scholarshipAdd: [
    {
      id: utils.randomId(),
      schoolAdd: '',
      careerAdd: '',
      schoolStatus: '',
      startDate: '',
      endDate: '',
    },
  ],
  languageAdd: [
    {
      id: utils.randomId(),
      language: '',
      spokenLanguage: 50,
      writtenLanguage: 50,
      languageRead: 50,
    },
  ],
  certificationAdd: [],
  courseAdd: [],
  studiesComments: '',
  jobExperienceAdd: [
    {
      id: utils.randomId(),
      company: '',
      jobAdd: '',
      start_date: '',
      endDate: '',
      inProgress: false,
      lastSalaryReceived: '',
      reasonForWithdrawal: '',
    },
  ],
  professionalExperienceComments: '',
  qualityAdd: [
    {
      id: utils.randomId(),
      quality: '',
    },
  ],
  opportunityAreaAdd: [
    {
      id: utils.randomId(),
      opportunity: '',
    },
  ],
  externalPerceptionsAdd: [
    {
      id: utils.randomId(),
      perception: '',
    },
  ],
  personalityComments: '',
  candidateRatingAdd: {
    examResult: 0,
    attitudeRating: 0,
    aptitudeRating: 0,
    globalRating: 0,
  },
  candidateFollowUpAdd: {
    status: 'IP',
    observations: '',
  },
}

export let selectedCandidateObject = {
  id: null,
  name: '',
  lastName: '',
  secondLastName: '',
  gender: '',
  minimumSalaryExpectation: '',
  maximumSalaryExpectation: '',
  employmentStatus: {
    id: null,
    status: '',
    isActive: null,
  },
  availabilityToStart: '',
  dateOfBirth: '',
  age: null,
  jobVacancy: {
    id: null,
    name: '',
    isActive: true,
  },
  nationality: {
    id: null,
    name: '',
    isActive: true,
  },
  recruitingMedium: {
    id: null,
    name: '',
    isActive: true,
  },
  benefits: [],
  emailsContact: [],
  address: {
    id: null,
    street: '',
    postalCode: {
      id: null,
      postalCode: '',
      settlement: '',
      municipality: '',
      city: '',
      estate: '',
    },
  },
  generalInformationComments: '',
  scholarship: [],
  language: [],
  certification: [],
  course: [],
  studiesComments: '',
  jobExperience: [],
  quality: [],
  professionalExperienceComments: null,
  opportunityArea: [],
  externalPerceptions: [],
  personalityComments: '',
  candidateDocument: {
    id: null,
    cv: null,
    summary: null,
    exam: null,
  },
  candidateRating: {
    id: null,
    examResult: 5,
    attitudeRating: 0,
    aptitudeRating: 0,
    globalRating: 0,
  },
  candidateFollowUp: {
    id: null,
    status: '',
    observations: '',
  },
  createdAt: null,
  modifiedBy: null,
}
