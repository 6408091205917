export let employeeObject = {
  candidate: 0,
  name: '',
  lastName: '',
  secondLastName: '',
  curp: '',
  rfc: '',
  nss: '',
  startDate: '',
  lowDate: '',
  numPayroll: '',
  jobPosition: '',
  internalMail: '',
  payrollSalary: '',
  mixedMonthlySalary: '',
  workArea: 0,
  entryTime: '',
  departureTime: '',
  reasonEnd: '',
  reasonForWithdrawal: null,
  endDate: '',
  contacts_add: [
    {
      idTemp: null,
      relationship: null,
      name: null,
      phoneNumber: null,
    },
  ],
}

export let selectedEmployeebject = {
  id: null,
  candidate: 0,
  name: '',
  lastName: '',
  secondLastName: '',
  curp: '',
  rfc: '',
  nss: '',
  startDate: '',
  lowDate: '',
  numPayroll: '',
  jobPosition: '',
  internalMail: '',
  payrollSalary: '',
  mixedMonthlySalary: '',
  workArea: null,
  entryTime: '',
  departureTime: '',
  phonesContactAdd: [],
  createdAt: null,
  modifiedBy: null,
}
