import { candidateObject } from '@/models/candidates'
const _ = require('lodash')

export default {
  setUpdateCandidate: (state, candidate) => {
    let updateCandidate = _.cloneDeep(candidate)
    // Starts to change the object to the final updateCandidateObject

    // Remove decimals
    updateCandidate.minimumSalaryExpectation = Math.trunc(
      updateCandidate.minimumSalaryExpectation
    )

    updateCandidate.maximumSalaryExpectation = Math.trunc(
      updateCandidate.maximumSalaryExpectation
    )
    // Formats the employment status
    updateCandidate.employmentStatusId = updateCandidate.employmentStatus.id
    delete updateCandidate.employmentStatus

    // Formats the job vacancy
    updateCandidate.jobVacancyId = updateCandidate.jobVacancy.id
    delete updateCandidate.jobVacancy

    // Formats the nationality
    updateCandidate.nationalityId = updateCandidate.nationality.id
    delete updateCandidate.nationality

    //Formats the recruiting medium
    updateCandidate.recruitingMediumId = updateCandidate.recruitingMedium.id
    delete updateCandidate.recruitingMedium

    // Formats the benefits
    updateCandidate.benefitsId = updateCandidate.benefits.map((item) => item.id)
    delete updateCandidate.benefits

    // Formats the email & phone contact
    updateCandidate.emailsContactAdd = updateCandidate.emailsContact.map(
      (item) => {
        return { id: item.id, email: item.email }
      }
    )
    updateCandidate.phonesContactAdd = updateCandidate.phonesContact.map(
      (item) => {
        return { id: item.id, phone: item.phone }
      }
    )
    delete updateCandidate.emailsContact
    delete updateCandidate.phonesContact

    // Formats Street
    updateCandidate.addressAdd = {
      street: updateCandidate.address.street,
      selectedPostalCode: {
        id: updateCandidate.address.postalCode.id,
        postalCode: updateCandidate.address.postalCode.postalCode,
        settlement: updateCandidate.address.postalCode.settlement,
        municipality: updateCandidate.address.postalCode.municipality,
        city: updateCandidate.address.postalCode.city,
        estate: updateCandidate.address.postalCode.estate,
      },
      postalCodeStr: updateCandidate.address.postalCode.postalCode,
    }
    delete updateCandidate.address

    // Edit scholarship
    updateCandidate.scholarshipAdd = updateCandidate.scholarship.map((item) => {
      return {
        schoolAdd: item.school.id,
        careerAdd: item.career.id,
        schoolStatus: item.schoolStatus,
        startDate: item.startDate,
        endDate: item.endDate,
      }
    })
    delete updateCandidate.scholarship

    // Edit language
    updateCandidate.languageAdd = updateCandidate.language.map((item) => {
      return {
        language: item.language,
        spokenLanguage: item.spokenLanguage,
        writtenLanguage: item.writtenLanguage,
        languageRead: item.languageRead,
      }
    })
    delete updateCandidate.language

    // Edit certification
    updateCandidate.certificationAdd = updateCandidate.certification.map(
      (item) => {
        return {
          certification: item.certification,
          year: item.year,
        }
      }
    )
    delete updateCandidate.certification

    // Edit course
    updateCandidate.courseAdd = updateCandidate.course.map((item) => {
      return {
        course: item.course,
        year: item.year,
      }
    })
    delete updateCandidate.course

    // Job Experience
    updateCandidate.jobExperienceAdd = updateCandidate.jobExperience.map(
      (item) => {
        return {
          company: item.company,
          jobAdd: item.job.id,
          startDate: item.startDate,
          endDate: item.endDate,
          inProgress: item.inProgress,
          lastSalaryReceived: Math.trunc(item.lastSalaryReceived),
          reasonForWithdrawal: item.reasonForWithdrawal,
        }
      }
    )
    delete updateCandidate.jobExperience

    // Quality
    updateCandidate.qualityAdd = updateCandidate.quality.map((item) => {
      return {
        quality: item.quality,
        id: item.id,
      }
    })
    delete updateCandidate.quality

    // opportunityAreaAdd
    updateCandidate.opportunityAreaAdd = updateCandidate.opportunityArea.map(
      (item) => {
        return {
          opportunity: item.opportunity,
          id: item.id,
        }
      }
    )
    delete updateCandidate.opportunityArea

    // externalPerceptions
    updateCandidate.externalPerceptionsAdd = updateCandidate.externalPerceptions.map(
      (item) => {
        return {
          perception: item.perception,
          id: item.id,
        }
      }
    )
    delete updateCandidate.externalPerceptions

    // candidateRatingAdd
    updateCandidate.candidateRatingAdd = {
      examResult: updateCandidate.candidateRating.examResult,
      attitudeRating: updateCandidate.candidateRating.attitudeRating,
      aptitudeRating: updateCandidate.candidateRating.aptitudeRating,
      globalRating: updateCandidate.candidateRating.globalRating,
    }

    delete updateCandidate.candidateRating

    // candidateFollowUp
    updateCandidate.candidateFollowUpAdd = {
      status: updateCandidate.candidateFollowUp.status,
      observations: updateCandidate.candidateFollowUp.observations,
    }

    delete updateCandidate.candidateFollowUp
    state.updateCandidate = _.cloneDeep(updateCandidate)
  },
  setNewCandidatePersonalData: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      name: newCandidate.name,
      lastName: newCandidate.lastName,
      secondLastName: newCandidate.secondLastName,
      minimumSalaryExpectation: newCandidate.minimumSalaryExpectation,
      maximumSalaryExpectation: newCandidate.maximumSalaryExpectation,
      employmentStatusId: newCandidate.employmentStatusId,
      availabilityToStart: newCandidate.availabilityToStart,
      dateOfBirth: newCandidate.dateOfBirth,
      nationalityId: newCandidate.nationalityId,
      jobVacancyId: newCandidate.jobVacancyId,
      recruitingMediumId: newCandidate.recruitingMediumId,
    })
  },
  setUpdateCandidatePersonalData: (state, updateaCandidate) => {
    state.updateaCandidate = _.merge(state.updateaCandidate, {
      name: updateaCandidate.name,
      lastName: updateaCandidate.lastName,
      secondLastName: updateaCandidate.secondLastName,
      minimumSalaryExpectation: updateaCandidate.minimumSalaryExpectation,
      maximumSalaryExpectation: updateaCandidate.maximumSalaryExpectation,
      employmentStatusId: updateaCandidate.employmentStatusId,
      availabilityToStart: updateaCandidate.availabilityToStart,
      dateOfBirth: updateaCandidate.dateOfBirth,
      nationalityId: updateaCandidate.nationalityId,
      jobVacancyId: updateaCandidate.jobVacancyId,
      recruitingMediumId: updateaCandidate.recruitingMediumId,
    })
  },
  setNewCandidateBenefits: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      benefitsId: newCandidate.benefitsId,
    })
  },
  setUpdateCandidateBenefits: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      benefitsId: updateCandidate.benefitsId,
    })
  },
  setNewCandidateContact: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      emailsContactAdd: newCandidate.emailsContactAdd,
      phonesContactAdd: newCandidate.phonesContactAdd,
    })
  },
  setUpdateCandidateContact: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      emailsContactAdd: updateCandidate.emailsContactAdd,
      phonesContactAdd: updateCandidate.phonesContactAdd,
    })
  },
  setNewCandidateAddress: (state, { newCandidate, selectedPostalCode }) => {
    state.newCandidate = _.merge(state.newCandidate, newCandidate, {
      addressAdd: {
        selectedPostalCode: selectedPostalCode,
      },
    })
  },
  setUpdateCandidateAddress: (
    state,
    { updateCandidate, selectedPostalCode }
  ) => {
    state.updateCandidate = _.merge(state.updateCandidate, updateCandidate, {
      addressAdd: {
        selectedPostalCode: selectedPostalCode,
      },
    })
  },
  setNewCandidateGeneralInformationComments: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      generalInformationComments: newCandidate.generalInformationComments,
    })
  },
  setUpdateCandidateGeneralInformationComments: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      generalInformationComments: updateCandidate.generalInformationComments,
    })
  },
  setNewCandidateStudiesScholarship: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      scholarshipAdd: newCandidate.scholarshipAdd,
    })
  },
  setUpdateCandidateStudiesScholarship: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      scholarshipAdd: updateCandidate.scholarshipAdd,
    })
  },
  setNewCandidateStudiesLanguage: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      languageAdd: newCandidate.languageAdd,
    })
  },
  setUpdateCandidateStudiesLanguage: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      languageAdd: updateCandidate.languageAdd,
    })
  },
  setNewCandidateStudiesCertification: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      certificationAdd: newCandidate.certificationAdd,
    })
  },
  setUpdateCandidateStudiesCertification: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      certificationAdd: updateCandidate.certificationAdd,
    })
  },
  setNewCandidateStudiesCourse: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      courseAdd: newCandidate.courseAdd,
    })
  },
  setUpdateCandidateStudiesCourse: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      courseAdd: updateCandidate.courseAdd,
    })
  },
  setNewCandidateStudiesComments: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      studiesComments: newCandidate.studiesComments,
    })
  },
  setUpdateCandidateStudiesComments: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      studiesComments: updateCandidate.studiesComments,
    })
  },
  setNewCandidateProfessionalExperienceJob: (state, newCandidate) => {
    newCandidate.jobExperienceAdd.map((jobExperience) => {
      if (jobExperience.inProgress) {
        jobExperience.endDate = null
        jobExperience.reasonForWithdrawal = null
      }
    })
    state.newCandidate = _.merge(state.newCandidate, {
      jobExperienceAdd: newCandidate.jobExperienceAdd,
    })
  },
  setUpdateCandidateProfessionalExperienceJob: (state, updateCandidate) => {
    updateCandidate.jobExperienceAdd.map((jobExperience) => {
      if (jobExperience.inProgress) {
        jobExperience.endDate = null
        jobExperience.reasonForWithdrawal = null
      }
    })
    state.updateCandidate = _.merge(state.updateCandidate, {
      jobExperienceAdd: updateCandidate.jobExperienceAdd,
    })
  },
  setNewCandidateProfessionalExperienceComments: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      professionalExperienceComments:
        newCandidate.professionalExperienceComments,
    })
  },
  setUpdateCandidateProfessionalExperienceComments: (
    state,
    updateCandidate
  ) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      professionalExperienceComments:
        updateCandidate.professionalExperienceComments,
    })
  },
  /**
   * Configura las cualidades del candidato.
   * @param state
   * @param newCandidate
   */
  setNewCandidatePersonalityQuality: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      qualityAdd: newCandidate.qualityAdd,
    })
  },
  /**
   * Configura las cualidades del candidato a editar.
   * @param state
   * @param updateCandidate
   */
  setUpdateCandidatePersonalityQuality: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      qualityAdd: updateCandidate.qualityAdd,
    })
  },
  /**
   * Configura las areas de oportunidad del candidato.
   * @param state
   * @param newCandidate
   */
  setNewCandidatePersonalityOpportunityArea: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      opportunityAreaAdd: newCandidate.opportunityAreaAdd,
    })
  },
  /**
   * Configura las areas de oportunidad del candidato a editar.
   * @param state
   * @param updateCnadidate
   */
  setUpdateCnadidatePersonalityOpportunityArea: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      opportunityAreaAdd: updateCandidate.opportunityAreaAdd,
    })
  },
  /**
   * Configura la percepción de externos del candidato.
   * @param state
   * @param newCandidate
   */
  setNewCandidatePersonalityExternalPerceptions: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      externalPerceptionsAdd: newCandidate.externalPerceptionsAdd,
    })
  },
  /**
   * Configura la percepción de externos del candidato a editar.
   * @param state
   * @param updateCandidate
   */
  setUpdateCandidatePersonalityExternalPerceptions: (
    state,
    updateCandidate
  ) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      externalPerceptionsAdd: updateCandidate.externalPerceptionsAdd,
    })
  },
  /**
   * Configura los comentarios de la sección de percepción de externo.
   * @param state
   * @param newCandidate
   */
  setNewCandidatePersonalityComments: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      personalityComments: newCandidate.personalityComments,
    })
  },
  /**
   * Configura los comentarios de la sección de percepción de externo del candidato a editar.
   * @param state
   * @param updateCandidate
   */
  setUpdateCandidatePersonalityComments: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      personalityComments: updateCandidate.personalityComments,
    })
  },
  setSelectedCandidate: (state, selectedCandidate) => {
    state.selectedCandidate = selectedCandidate
  },
  setSelectedCandidateDocuments: (state, docs) => {
    state.selectedCandidate.candidateDocument = docs
  },
  clearNewCandidateData: (state) => {
    state.newCandidate = _.cloneDeep(candidateObject)
    state.newCandidateFormsProgress = {
      generalInformation: {
        candidateData: false,
        benefits: false,
        contact: false,
        address: false,
        comments: false,
      },
      studies: {
        scholarship: false,
        languages: false,
        certifications: false,
        courses: false,
        comments: false,
      },
      jobExperience: {
        jobExperience: false,
        comments: false,
      },
      personality: {
        qualities: false,
        opportunities: false,
        perceptions: false,
      },
      docs: {
        ratings: false,
      },
    }
  },
  clearUpdateCandidateData: (state) => {
    state.updateCandidate = _.cloneDeep(candidateObject)
  },
  setNewCandidateProgress: (state, { section, card, status }) => {
    state.newCandidateFormsProgress[section][card] = status
  },
  setNewCandidateErrors: (state, errors) => {
    state.newCandidateErrors = _.cloneDeep(errors)
  },
  setUpdateCandidateErrors: (state, errors) => {
    state.updateCandidateErrors = _.cloneDeep(errors)
  },
  setCandidatesList: (state, candidatesList) => {
    state.candidatesList = candidatesList
  },
  setCandidateDocs: (state, formData) => {
    state.candidateDocs = formData
  },
  setNewCandidateRatings: (state, newCandidate) => {
    state.newCandidate = _.merge(state.newCandidate, {
      candidateRatingAdd: newCandidate.candidateRatingAdd,
    })
  },
  setUpdateCandidateRatings: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      candidateFollowUp: updateCandidate.candidateFollowUp,
    })
  },
  setUpdateCandidateFollowUp: (state, updateCandidate) => {
    state.updateCandidate = _.merge(state.updateCandidate, {
      candidateFollowUpAdd: updateCandidate.candidateRatingAdd,
    })
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setSkeletonLoading: (state, isLoading) => {
    state.skeletonLoading = isLoading
  },
  setTotalCandidatesPages: (state, pages) => {
    state.totalCandidatesPages = pages
  },
}
