import Vue from 'vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#2962ff',
      success: '#388e3c',
      danger: '#d32f2f',
      warning: '#ff6f00',
      dark: '#002f6c',
    },
  },
})
