export default {
  users: [],
  emailError: false,
  company: {
    id: null,
    name: '',
    validUntil: null,
    unlimited: false,
    picture: '#',
  },
}
