import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function getEmploymentStatusCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/employment-status/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSchoolCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/schools/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function getJobVacancyCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/jobs-vacancy/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function getJobCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/jobs/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function getRecruitingMediumCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/recruiting-medium/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function getNationalityCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/nationality/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getBenefitCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/benefits/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSchoolsCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/schools/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSchoolCareerCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/school-career/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSchoolStatusCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/school-status/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getLanguagesCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/languages/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getReasonForWithdrawalCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/withdrawal-reason/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getPostalCodesCatalog(store, postalCode) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/catalogs/postal-codes/?postal_code=${postalCode}`)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getAreaCatalog({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/catalogs/area/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getPayrollPositionCatalog({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/catalogs/payroll-position/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getRelationshipCatalog({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/catalogs/relationship/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function newItemCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/${type}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateItemCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
