import axios from 'axios'

export async function inviteUser({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando Email',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getCompany({ commit }, companyId) {
  return new Promise((resolve, reject) => {
    let url = `/companies/${companyId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateCompany({ commit }, { data, companyId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/companies/${companyId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updatePictureCompany(
  { commit },
  { formData, companyId }
) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/companies/${companyId}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
